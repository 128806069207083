@import '../../scss/breakpoints';
@import '../../scss/colors';

.diceCalculatorWrapper {
	display: flex;
	flex-direction: column;
	max-width: 300px;
	margin: auto;

	@media only screen and (max-width: $smallScreenBreakPoint) {
		width: 100%;
		max-width: none;
        justify-content: center;
    }
}

.diceCalculator {
	display: flex;
	flex-direction: row;
}

.calculatorNumbers {
	display: flex;
	flex-wrap: wrap;
}

.calculatorDice {
	display: flex;
	flex-wrap: wrap;
	margin-left: 0.2em;
}

.calculatorButton {
	margin: 0.1em;
	border-radius: 0.6em;
	flex: 1;

	&.calculatorPlusButton {
		flex: 2;
	}
}

.actionButtons {
	display: flex;

	.rollButton {
		background-color: $colorGreen;
		border: 1px solid $colorGreen;
		flex: 2;
	}
	.clearButton {
		background-color: $colorRed;
		border: 1px solid $colorRed;
		flex: 1;
	}
	.backButton {
		background-color: $colorGrayBold;
		border: 1px solid $colorGrayBold;
		flex: 1;
	}
}

.rerollButton {
	width: 100%;
	flex: 1;
	font-weight: 600;
	letter-spacing: 0.10em;
}

.calculatorHorizontalDivider {
	height: 5em;	
	position: relative;

	.calculatorHorizontalDividerLine {
		position: absolute;
		width: 100%;
		top: 2em;
	}

	.calculatorHorizontalDividerText {
		position: absolute;
		margin: auto;
		width: 100%;
		top: 1.75em;

		span {
			background-color: $colorGrayLight;
			padding: 0 1em;
			font-weight: 700;
		}
	}
}