@import '../../scss/colors';
@import '../../scss/spacing';
@import '../../scss/breakpoints';

.commonCard {
	width: 100%;
	background-color: $colorGrayLight;
	border-radius: $cardBorderRadius;
	margin-bottom: 1em;

	.commonCardHeader {
		background-color: $colorAccent;
		border-top-left-radius: $cardBorderRadius;
		border-top-right-radius: $cardBorderRadius;
		color: $colorWhiteBold;
		font-weight: 700;
		font-family: 'Lato', sans-serif;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		padding: 1em;

		span {
			font-size: 1.3em;
		}

		@media only screen and (max-width: $smallScreenBreakPoint) {
			 span {
			 	font-size: 1em;
			 }
	     }
	}

	.commonCardContent {
		padding: 1em 2em 2em 2em;
		color: $colorGrayBold;

		@media only screen and (max-width: $smallScreenBreakPoint) {
			 p {
			 	font-size: 0.9em;
			 }
	     }
	}
}