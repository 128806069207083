@import '../../scss/colors';
@import '../../scss/breakpoints';

.menuBar {
	background-color: $colorGreenBold;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: $colorWhiteBold;
	text-align: right;

	ul {
		list-style-type: none;
		display: flex;
		justify-content: flex-end;
		margin: 0 2em;

		li {
			padding: 1em;			

			&:hover {
				background-color: $colorAccentBold;
			}
		}

		a li {
			color: $colorWhiteBold;
		}
	}
}