@import '../../scss/colors';

.diceTextForm {
	display: flex;
	flex-direction: column;

	input {
		text-align: center;
		background-color: transparent;
		color: $colorAccentLight;
		font-size: 1.2em;
		font-family: 'Lato', sans-serif;
		letter-spacing: 0.05em;
		margin: 1em;
	}
	input::placeholder {
		color: $colorGray;
	}
}

.diceTextButton {
	margin: 1em 0;

	button {
		width: 100%;
	}
}