$colorGreen: #36827F;
$colorGreenBold: #245655;
$colorGreenLight: #52B7B3;

$colorRed: #A24936;
$colorRedBold: #7A3829;
$colorRedLight: #C76B57;

$colorAccent: #A77464;
$colorAccentBold: #905F51;
$colorAccentLight: #B59589;

$colorGray: #d7d7d7;
$colorGrayBold: #818181;
$colorGrayLight: #EAEAEA;

$colorWhiteBold: #F4F4F4;