@import '../../scss/breakpoints';
@import '../../scss/colors';

.authCard {

	h1 {
		line-height: 1.5em;
	}

	p a {
		color: $colorGreenBold;
		text-decoration: none;

		&:hover {
			font-weight: 700;
		}
	}

	@media only screen and (max-width: $smallScreenBreakPoint) {
        font-size: 0.8em;
    }
}

.authOptions {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	@media only screen and (max-width: $smallScreenBreakPoint) {
        flex-direction: column;
        justify-content: center;
    }
}

.authOption {
	padding: 1em 2em;
	margin: 1em 0.5em 0 0.5em;
	background-color: $colorAccentLight;
	border-radius: 0.5em;
	color: $colorWhiteBold;
	flex: 1;
}

.authEmailForm {
	display: flex;
	flex-direction: column;
	padding: 2em 0;

	.authEmailFormInput {
		margin-bottom: 2em;
	}

	input[type=text], input[type=password] {
		height: 2em;
		background-color: $colorGray;
	}
}

.errorMessage {
	color: $colorRed;
	font-size: 0.8em;
	padding-top: 0.5em;
	display: none;

	&.visible {
		display: block;
	}
}