@import './scss/typography';
@import './scss/spacing';
@import './scss/colors';
@import './scss/breakpoints';

html, body {
	margin: 0;
	padding: 0;
	display: block;
	font-size: 16px;
	background-color: $colorGreen;
	//background-image: linear-gradient(to left top, rgba(54, 130, 127, 0.88), rgba(36, 86, 85, 0.78)), url('./assets/images/background.jpg');
	background-size: contain;
}

body {
	margin-bottom: 3em;
	line-height: 1.5em;
	font-family: 'Roboto', sans-serif;
}

a {
	text-decoration: none;
}

