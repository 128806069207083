@import '../../scss/colors';

.commonInputWrapper {
	display: flex;
	flex-direction: column;
}

.commonInput {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $colorGray;
	padding: 0.8em 1em;
}

.commonInput:focus {
	border-bottom: 1px solid $colorAccentLight;
	outline: none; 	
}

.commonInputBottomText {
	font-size: 0.7em;
	text-align: left;
	margin-top: 0.1em;

	&.error {
		color: $colorRedLight;
	}
}