@import '../../scss/colors';
@import '../../scss/breakpoints';

.siteHeader {
    padding: 7em 1em 3em 2em;
}

.siteName a {
    display: flex;
    font-family: 'Lato', sans-serif;
    font-size: 3em;
    font-variant: small-caps;
    font-weight: 900;
    letter-spacing: 0.05em;
    color: $colorWhiteBold;
    text-decoration: none;

    @media only screen and (max-width: $smallScreenBreakPoint) {
        font-size: 2em;
        justify-content: center;
    }
}