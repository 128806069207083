@import '../../scss/colors';

.commonButton {
	padding: 1em;
	border: 1px solid $colorAccentLight;
	border-radius: 0.8em;
	background-color: $colorAccentLight;
	color: $colorWhiteBold;
}

.commonButton:hover {
	background-color: $colorGreen;
	cursor: pointer;
}

.commonButton:focus {
	outline: none;
}