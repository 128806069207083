@import '../../scss/spacing';
@import '../../scss/colors';

.sidebar {
	min-height: 100%;
	height: 100vh;
	width: $sidebarWidth;
	position: fixed;
	top: 0;
	right: 0;
	background-color: $colorAccentBold;
	display: none;

	&.sidebarVisible {
		display: block;
	}
}

.sidebarItem {
	color: $colorGrayLight;
	text-transform: uppercase;
	font-weight: 700;
	padding: 1em;
	padding-left: 0em;
	margin: 1em 2em;
	text-align: left;
	cursor: pointer;
	border-bottom: 1px solid $colorGrayLight;
}