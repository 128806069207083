@import '../../scss/colors';
@import '../../scss/breakpoints';

.historyTable {

	div {
		margin: 0.1em 0.1em 0 0.1em;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.nameColumn {
		width: 35%;
		box-sizing: border-box;
	}

	.timeStampColumn {
		width: 35%;
		box-sizing: border-box;
		@media only screen and (max-width: $smallScreenBreakPoint) {
			display: none;
	    }
	}

	.diceColumn {
		width: 35%;
		box-sizing: border-box;
	}

	.resultsColumn {
		width: 35%;
		box-sizing: border-box;
	}

	.tableHeader {
		display: flex;
		width: 100%;
		border-bottom: 2px solid $colorGray;

		div {
			border-radius: 0.3em;
			padding: 0.7em;
			color: $colorGrayBold;
			font-weight: 700;
			font-size: 0.8em;
			text-transform: uppercase;
			font-family: 'Lato', sans-serif;
			letter-spacing: 0.1em;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;
		margin: 0;
		width: 100%;
		
		li {
			display: flex;
			width: 100%;
			border-bottom: 1px solid $colorWhiteBold;
			color: $colorGrayBold;
			font-weight: 100;
			font-size: 0.8em;

			@media only screen and (max-width: $smallScreenBreakPoint) {
				font-weight: 400;
		    }


			div { 
				padding: 0.3em;
			}
		}
	}
}