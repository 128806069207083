@import './scss/spacing';

.app {
	text-align: center;
}

.mainPageWrapper {
	max-width: calc(100% - #{$sidebarWidth});
	position: relative;
	margin: 0;
	padding: 0;

	&.mainPageFull {
		max-width: 100%;
	}
}

.mainPage {
	max-width: 800px;
	width: 90%;
	margin: auto;
}