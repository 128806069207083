@import '../../scss/colors';
@import '../../scss/breakpoints';

.diceDisplay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 1em 0;
	background-color: $colorAccentLight;
	color: $colorAccent;
	border-radius: 2em;
	padding: 2em;
}

.diceResults {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 2em;
}

.diceTotal {
	display: none;
	justify-content: flex-start;
	padding: 0.5em 0.5em 0 0.5em;
	color: $colorAccentLight;
	font-size: 1.3em;

	&.visible {
		display: flex;
	}
}

.defaultText {
	font-size: 2em;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	margin: 2em;

	@media only screen and (max-width: $smallScreenBreakPoint) {
		font-size: 1.5em;
		margin: 1em;
    }

}

.graphicDie {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4em;
	height: 4em;
	position: relative;
	margin: 0.6em;

	span {
		display: none;

		&.activeDie {
			display: inline;
		}
	}

	svg rect, polygon{
		z-index: 1;
		fill: $colorAccentLight;
		stroke: $colorAccentLight;
		stroke-width: 0px;

		&.dieLightShadow {
			fill: #B68A7C;
			z-index: 2;
		}

		&.dieShadow {
			fill: $colorAccent;
			z-index: 3;
		}

		&.dieShadowDark {
			fill: $colorAccentBold;
			z-index: 4;
		}
	}

	p {
		margin: auto;
		color: $colorWhiteBold;
		font-size: 1.2em;
		position: absolute;
		z-index: 5;
	}
}